import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseParameter } from './BaseParameter.model';
import { NamThang } from './NamThang.model';

@Injectable({
    providedIn: 'root'
})
export class DownloadService {

    ListNam001: NamThang[] | undefined;
    ListThang001: NamThang[] | undefined;

    BaseParameter!: BaseParameter;
    APIURL: string = environment.APIUploadURL;
    Controller: string = "Download";
    Headers: HttpHeaders = new HttpHeaders();


    IPAddress: string = environment.InitializationString;


    constructor(private httpClient: HttpClient) {
        this.InitializationFormData();
        this.GetIPAddress();
    }
    InitializationFormData() {
        this.BaseParameter = {
        };

        let token = localStorage.getItem(environment.Token);
        this.Headers = this.Headers.append('Authorization', 'Bearer ' + token);
    }
    GetRandomColor(count) {
        var arr = [];
        for (var i = 0; i < count; i++) {
            var letters = '0123456789ABCDEF'.split('');
            var color = '#';
            for (var x = 0; x < 6; x++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
        }
        return color;
    }
    GetIPAddress() {
        // if (this.IPAddress.length == 0) {
        //     this.httpClient.get(environment.IPRegistry).toPromise().then(res => {
        //         this.IPAddress = res["ip"];
        //     });
        // }      

        return this.httpClient.get(environment.IPRegistry).toPromise();
    }
    GetIPData() {
        return this.httpClient.get(environment.IPRegistryURL).toPromise();
    }
    ComponentGetListNam() {
        this.ListNam().subscribe(
            res => {
                this.ListNam001 = (res as NamThang[]).sort((a, b) => (a.ID > b.ID ? 1 : -1));
            },
            err => {
            }
        );
    }
    ComponentGetListThang() {
        this.ListThang().subscribe(
            res => {
                this.ListThang001 = (res as NamThang[]).sort((a, b) => (a.ID > b.ID ? 1 : -1));
            },
            err => {
            }
        );
    }
    NamBatDau() {
        let url = this.APIURL + this.Controller + '/NamBatDau';
        const formUpload: FormData = new FormData();
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }

    NamKeThuc() {
        let url = this.APIURL + this.Controller + '/NamKeThuc';
        const formUpload: FormData = new FormData();
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    ListNam() {
        let url = this.APIURL + this.Controller + '/ListNam';
        const formUpload: FormData = new FormData();
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    ListThang() {
        let url = this.APIURL + this.Controller + '/ListThang';
        const formUpload: FormData = new FormData();
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }    
    ExportTemPhieuByParentIDToExcelAsync() {
        let url = this.APIURL + this.Controller + '/ExportTemPhieuByParentIDToExcelAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

