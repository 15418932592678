import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoHangTapTinDinhKem } from 'src/app/shared/LoHangTapTinDinhKem.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class LoHangTapTinDinhKemService extends BaseService{

    DisplayColumns001: string[] = ['DanhMucTapTinDinhKemPhanLoaiID', 'NgayCap', 'Save'];
        
    List: LoHangTapTinDinhKem[] | undefined;
    ListFilter: LoHangTapTinDinhKem[] | undefined;
    FormData!: LoHangTapTinDinhKem;

    

    List001: LoHangTapTinDinhKem[] | undefined;
    List002: LoHangTapTinDinhKem[] | undefined;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "LoHangTapTinDinhKem";
    }
    GetByDanhMucTapTinDinhKemPhanLoaiIDToListAsync() {
        let url = this.APIURL + this.Controller + '/GetByDanhMucTapTinDinhKemPhanLoaiIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

