import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { EmptyComponent } from './empty/empty.component';

const routes: Routes = [
  { path: '', redirectTo: '/Empty', pathMatch: 'full' },
  {
    path: 'Homepage', component: HomepageComponent,
  },  
  // {
  //   path: ':ID', component: HomepageComponent,
  // },
  {
    path: 'Homepage/:ID', component: HomepageComponent,
  },
  {
    path: 'Empty', component: EmptyComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }









































































