import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { LoHangTapTinDinhKem } from 'src/app/shared/LoHangTapTinDinhKem.model';
import { LoHangTapTinDinhKemService } from 'src/app/shared/LoHangTapTinDinhKem.service';

@Component({
  selector: 'app-lo-hang-tap-tin-dinh-kem-detail',
  templateUrl: './lo-hang-tap-tin-dinh-kem-detail.component.html',
  styleUrls: ['./lo-hang-tap-tin-dinh-kem-detail.component.css']
})
export class LoHangTapTinDinhKemDetailComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<LoHangTapTinDinhKemDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public LoHangTapTinDinhKemService: LoHangTapTinDinhKemService,
  ) { }

  ngOnInit(): void {
  }
  Close() {
    this.dialogRef.close();
  }
}
