import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { TemPhieu } from 'src/app/shared/TemPhieu.model';
import { TemPhieuService } from 'src/app/shared/TemPhieu.service';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.css']
})
export class EmptyComponent implements OnInit {

  constructor(
    public router: Router,
    public TemPhieuService: TemPhieuService,
  ) { }

  ngOnInit(): void {
  }
  
  TemPhieuSearch001() {
    if (this.TemPhieuService.BaseParameter.MaSo) {
      if (this.TemPhieuService.BaseParameter.MaSo.length > 0) {
        this.TemPhieuService.IsShowLoading = true;
        this.TemPhieuService.GetByMaSoAsync().subscribe(
          res => {
            this.TemPhieuService.FormData = res as TemPhieu;
            if (this.TemPhieuService.FormData.URL) {
              if (this.TemPhieuService.FormData.URL.length > 0) {
                window.open(this.TemPhieuService.FormData.URL, "_blank");
              }
              else {
                this.router.navigate(['/' + environment.Empty]);
              }
            }
            else {
              this.router.navigate(['/' + environment.Empty]);
            }
            this.TemPhieuService.IsShowLoading = false;
          },
          err => {
            this.TemPhieuService.IsShowLoading = false;
          }
        );
      }
    }
  }
  TemPhieuSearch002() {
    if (this.TemPhieuService.BaseParameter.VanChuyenGTIN) {
      if (this.TemPhieuService.BaseParameter.VanChuyenGTIN.length > 0) {
        this.TemPhieuService.IsShowLoading = true;
        this.TemPhieuService.GetByVanChuyenGTINAsync().subscribe(
          res => {
            this.TemPhieuService.FormData = res as TemPhieu;
            if (this.TemPhieuService.FormData.URL) {
              if (this.TemPhieuService.FormData.URL.length > 0) {
                window.open(this.TemPhieuService.FormData.URL, "_blank");
              }
              else {
                this.router.navigate(['/' + environment.Empty]);
              }
            }
            else {
              this.router.navigate(['/' + environment.Empty]);
            }
            this.TemPhieuService.IsShowLoading = false;
          },
          err => {
            this.TemPhieuService.IsShowLoading = false;
          }
        );
      }
    }
  }
  TemPhieuSearch003() {
    if (this.TemPhieuService.BaseParameter.MaLoHang) {
      if (this.TemPhieuService.BaseParameter.MaLoHang.length > 0) {
        this.TemPhieuService.IsShowLoading = true;
        this.TemPhieuService.GetByMaLoHangAsync().subscribe(
          res => {
            this.TemPhieuService.FormData = res as TemPhieu;
            if (this.TemPhieuService.FormData.URL) {
              if (this.TemPhieuService.FormData.URL.length > 0) {
                window.open(this.TemPhieuService.FormData.URL, "_blank");
              }
              else {
                this.router.navigate(['/' + environment.Empty]);
              }
            }
            else {
              this.router.navigate(['/' + environment.Empty]);
            }
            this.TemPhieuService.IsShowLoading = false;
          },
          err => {
            this.TemPhieuService.IsShowLoading = false;
          }
        );
      }
    }
  }
}
