import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { DownloadService } from 'src/app/shared/Download.service';
import { NotificationService } from 'src/app/shared/Notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  PageTitle = environment.PageTitle;    
  constructor(
    public router: Router,   
  ) {
    
  }
  ngOnInit(): void {
    
  }
  ngAfterViewInit() {
  }
  
}
