import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { LoHangBinhChon } from 'src/app/shared/LoHangBinhChon.model';
import { LoHangBinhChonService } from 'src/app/shared/LoHangBinhChon.service';

@Component({
  selector: 'app-lo-hang-binh-chon-detail',
  templateUrl: './lo-hang-binh-chon-detail.component.html',
  styleUrls: ['./lo-hang-binh-chon-detail.component.css']
})
export class LoHangBinhChonDetailComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<LoHangBinhChonDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public LoHangBinhChonService: LoHangBinhChonService,
  ) { }

  ngOnInit(): void {
  }

  LoHangBinhChonSave() {    
    this.LoHangBinhChonService.FormData.IPAddress = localStorage.getItem(environment.IPRegistryIP);

    if ((this.LoHangBinhChonService.FormData.IPAddress == null) || (this.LoHangBinhChonService.FormData.IPAddress.length == 0)) {
      this.DownloadService.GetIPData().then(res => {
        this.LoHangBinhChonService.FormData.IPAddress = res["ip"];
        this.LoHangBinhChonService.FormData.TypeName = res["user_agent"]["device"]["type"];
        this.LoHangBinhChonService.FormData.KinhDo = res["location"]["longitude"];
        this.LoHangBinhChonService.FormData.ViDo = res["location"]["latitude"];
        this.LoHangBinhChonService.FormData.DanhMucQuocGiaName = res["location"]["country"]["name"];
        this.LoHangBinhChonService.FormData.DanhMucTinhThanhName = res["location"]["region"]["name"];

        localStorage.setItem(environment.IPRegistryIP, this.LoHangBinhChonService.FormData.IPAddress);
        localStorage.setItem(environment.IPRegistryDevice, this.LoHangBinhChonService.FormData.TypeName);
        localStorage.setItem(environment.IPRegistryLongitude, this.LoHangBinhChonService.FormData.KinhDo);
        localStorage.setItem(environment.IPRegistryLatitude, this.LoHangBinhChonService.FormData.ViDo);
        localStorage.setItem(environment.IPRegistryCountryName, this.LoHangBinhChonService.FormData.DanhMucQuocGiaName);
        localStorage.setItem(environment.IPRegistryRegionName, this.LoHangBinhChonService.FormData.DanhMucTinhThanhName);

      }).catch(error => {
      }).finally(() => {
        this.LoHangBinhChonSave001(); 
      });
    }
    else{
      this.LoHangBinhChonService.FormData.IPAddress = localStorage.getItem(environment.IPRegistryIP);
      this.LoHangBinhChonService.FormData.TypeName = localStorage.getItem(environment.IPRegistryDevice);
      this.LoHangBinhChonService.FormData.KinhDo = localStorage.getItem(environment.IPRegistryLongitude);
      this.LoHangBinhChonService.FormData.ViDo = localStorage.getItem(environment.IPRegistryLatitude);
      this.LoHangBinhChonService.FormData.DanhMucQuocGiaName = localStorage.getItem(environment.IPRegistryCountryName);
      this.LoHangBinhChonService.FormData.DanhMucTinhThanhName = localStorage.getItem(environment.IPRegistryRegionName);
      this.LoHangBinhChonSave001();
    }
  }
  LoHangBinhChonSave001() {
    this.LoHangBinhChonService.IsShowLoading = true;
    this.LoHangBinhChonService.SaveAsync().subscribe(
      res => {
        this.LoHangBinhChonService.FormData = res as LoHangBinhChon;
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangBinhChonService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangBinhChonService.IsShowLoading = false;
      }
    );
  }
  Close() {
    this.dialogRef.close();
  }
}