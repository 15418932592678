import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { LoHangPhanAnh } from 'src/app/shared/LoHangPhanAnh.model';
import { LoHangPhanAnhService } from 'src/app/shared/LoHangPhanAnh.service';


@Component({
  selector: 'app-lo-hang-phan-anh-detail',
  templateUrl: './lo-hang-phan-anh-detail.component.html',
  styleUrls: ['./lo-hang-phan-anh-detail.component.css']
})
export class LoHangPhanAnhDetailComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<LoHangPhanAnhDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public LoHangPhanAnhService: LoHangPhanAnhService,
  ) { }

  ngOnInit(): void {
  }

  LoHangPhanAnhSave() {
    this.LoHangPhanAnhService.FormData.IPAddress = localStorage.getItem(environment.IPRegistryIP);

    if ((this.LoHangPhanAnhService.FormData.IPAddress == null) || (this.LoHangPhanAnhService.FormData.IPAddress.length == 0)) {
      this.DownloadService.GetIPData().then(res => {
        this.LoHangPhanAnhService.FormData.IPAddress = res["ip"];
        this.LoHangPhanAnhService.FormData.TypeName = res["user_agent"]["device"]["type"];
        this.LoHangPhanAnhService.FormData.KinhDo = res["location"]["longitude"];
        this.LoHangPhanAnhService.FormData.ViDo = res["location"]["latitude"];
        this.LoHangPhanAnhService.FormData.DanhMucQuocGiaName = res["location"]["country"]["name"];
        this.LoHangPhanAnhService.FormData.DanhMucTinhThanhName = res["location"]["region"]["name"];

        localStorage.setItem(environment.IPRegistryIP, this.LoHangPhanAnhService.FormData.IPAddress);
        localStorage.setItem(environment.IPRegistryDevice, this.LoHangPhanAnhService.FormData.TypeName);
        localStorage.setItem(environment.IPRegistryLongitude, this.LoHangPhanAnhService.FormData.KinhDo);
        localStorage.setItem(environment.IPRegistryLatitude, this.LoHangPhanAnhService.FormData.ViDo);
        localStorage.setItem(environment.IPRegistryCountryName, this.LoHangPhanAnhService.FormData.DanhMucQuocGiaName);
        localStorage.setItem(environment.IPRegistryRegionName, this.LoHangPhanAnhService.FormData.DanhMucTinhThanhName);

      }).catch(error => {
      }).finally(() => {
        this.LoHangPhanAnhSave001(); 
      });
    }
    else{
      this.LoHangPhanAnhService.FormData.IPAddress = localStorage.getItem(environment.IPRegistryIP);
      this.LoHangPhanAnhService.FormData.TypeName = localStorage.getItem(environment.IPRegistryDevice);
      this.LoHangPhanAnhService.FormData.KinhDo = localStorage.getItem(environment.IPRegistryLongitude);
      this.LoHangPhanAnhService.FormData.ViDo = localStorage.getItem(environment.IPRegistryLatitude);
      this.LoHangPhanAnhService.FormData.DanhMucQuocGiaName = localStorage.getItem(environment.IPRegistryCountryName);
      this.LoHangPhanAnhService.FormData.DanhMucTinhThanhName = localStorage.getItem(environment.IPRegistryRegionName);
      this.LoHangPhanAnhSave001();
    }
  }
  LoHangPhanAnhSave001() {
    this.LoHangPhanAnhService.IsShowLoading = true;
    this.LoHangPhanAnhService.SaveAsync().subscribe(
      res => {
        this.LoHangPhanAnhService.FormData = res as LoHangPhanAnh;
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangPhanAnhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangPhanAnhService.IsShowLoading = false;
      }
    );
  }
  Close() {
    this.dialogRef.close();
  }
}
