import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TemPhieuLichSuTruyCap } from 'src/app/shared/TemPhieuLichSuTruyCap.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class TemPhieuLichSuTruyCapService extends BaseService{

    DisplayColumns001: string[] = ['ID', 'ParentID', 'Name', 'DangKy'];
        
    List: TemPhieuLichSuTruyCap[] | undefined;
    ListFilter: TemPhieuLichSuTruyCap[] | undefined;
    FormData!: TemPhieuLichSuTruyCap;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "TemPhieuLichSuTruyCap";
    }
}

